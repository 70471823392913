<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white">
    <div class="relative z-20 shadow">
      <div
        class="flex items-center justify-between px-4 py-5 mx-auto max-w-7xl sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10"
      >
        <div>
          <a
            href="#"
            class="flex"
          >
            <span class="sr-only">Compagnie Logo</span>
            <!-- TODO use soccer website with import -->
            <img
              class="w-auto h-8 sm:h-10"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt=""
            >
          </a>
        </div>
        <div class="-my-2 -mr-2 md:hidden">
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            @click="mobileMenuOpen = !mobileMenuOpen"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div
          class="hidden md:flex-1 md:flex md:items-center md:justify-between"
        >
          <nav class="flex space-x-10">
            <span
              v-for="(navLink, index) in navLinklist"
              :key="index"
            >
              <v-dropdown-link
                v-if="routeHasChildren(navLink)"
                :name="navLink.name"
                :active="navLink.dropdownActive"
                @clicked="clickedDropdownLink(index)"
              />
              <router-link
                v-else
                :to="navLink.to"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                {{ navLink.name }}
              </router-link>
            </span>
          </nav>
          <div class="flex items-center md:ml-12">
            <a
              href="#"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Sign in
            </a>
            <a
              href="#"
              class="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>
    </div>

    <v-flyout-menu
      v-for="navLink in navLinklistDropdown"
      :key="navLink.name"
      :active="navLink.dropdownActive"
      :menu-items="navLink.children"
    />

    <!-- Mobile menu -->
    <div
      :class="[
        mobileMenuOpen
          ? 'opacity-100 scale-100 z-30'
          : 'opacity-0 scale-95 z-0',
        'absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden',
      ]"
    >
      <div
        class="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50"
      >
        <div class="px-5 pt-5 pb-6 sm:pb-8">
          <div class="flex items-center justify-between">
            <div class="-mr-2">
              <button
                type="button"
                class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                @click="mobileMenuOpen = !mobileMenuOpen"
              >
                <span class="sr-only">Close menu</span>
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-6 sm:mt-8">
            <nav>
              <div class="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                <router-link
                  v-for="navLink in mobileNavLinkPrimary"
                  :key="navLink.name"
                  class="flex items-center p-3 -m-3 rounded-lg hover:bg-white"
                  :to="navLink.to"
                >
                  <div
                    class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-primary-500 sm:h-12 sm:w-12"
                  >
                    <i
                      :class="`${navLink.icon} fa-lg`"
                    /> 
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    {{ navLink.name }}
                  </div>
                </router-link>
              </div>
            </nav>
          </div>
        </div>
        <div class="px-5 py-6">
          <div class="grid grid-cols-2 gap-4">
            <router-link
              v-for="navLink in mobileNavLinkSecondary"
              :key="navLink.name"
              :to="navLink.to"
              class="text-base font-medium text-gray-900 rounded-md"
            >
              {{ navLink.name }}
            </router-link>
          </div>
          <div
            v-if="hasLogin"
            class="mt-6"
          >
            <a
              href="#"
              class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700"
            >
              Sign up
            </a>
            <p class="mt-6 text-base font-medium text-center text-gray-500">
              Existing customer?
              <a
                href="#"
                class="text-primary-600 hover:text-primary-500"
              >
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { routes } from '@/router';
import dropdownLinkVue from './dropdownLink.vue';
import flyoutmenu from './flyoutmenu.vue';

export default {
    components : {
        'v-dropdown-link' : dropdownLinkVue,
        'v-flyout-menu' : flyoutmenu,
    },
    props : {
        hasLogin : {
            type : Boolean,
            required : false,
            default : false,
        }
        //TODO hasSocialMedia
    },
    data(){
        return{
            mobileMenuOpen : false,
            navLinklist : [],
            mobileNavLinkPrimary : [],
            mobileNavLinkSecondary : [],
        };
    },
    computed : {
        navLinklistDropdown(){
            return this.navLinklist.filter((navLink) => navLink.children !== undefined);
        },
    },
    watch: {
        $route() {
            if(this.navLinklist.length > 0) {
                this.closeDropdownLink();
                this.mobileMenuOpen = false;
            }
        }
    },
    created(){
        this.generateNavLinks();
    },
    methods : {
        routeHasChildren(route){
            return this.$_.has(route, 'children');
        },
        generateNavLinks(){
            routes.forEach(route => {
                if(this.$_.has(route, 'navbar') && route.navbar === true){
                    // has children
                    if(this.$_.has(route, 'children')){
                        let children = [];
                        route.children.forEach((child) => {
                            children.push(this.navlinkObjectRender(child));
                        });
                        this.navLinklist.push(this.navlinkObjectRender(route, children));
                    }
                    //has no children
                    else{
                        this.navLinklist.push(this.navlinkObjectRender(route));
                    }
                }
            });
        },
        generateMobileNavLinks(){
            this.navLinklist.forEach((navLink) => {
                if(this.$_.has(navLink, 'children') && navLink.children.length > 0 && this.mobileNavLinkPrimary.length < 4){
                    navLink.children.forEach((childNavLink) => {
                        if(this.mobileNavLinkPrimary.length < 4) this.mobileNavLinkPrimary.push(childNavLink);
                    });
                }
                else{
                    if(this.mobileNavLinkSecondary.length < 6){
                        this.mobileNavLinkSecondary.push(navLink);
                    }
                }
            });
        },
        clickedDropdownLink(dropdownIndex = null){
            if(dropdownIndex !== null){
                let nextState = !this.navLinklist[dropdownIndex].dropdownActive;
                this.closeDropdownLink();
                this.navLinklist[dropdownIndex].dropdownActive = nextState;
                return;
            }
            this.closeDropdownLink();

        },
        closeDropdownLink(){
            this.navLinklist.forEach((navLink, index) => {
                if(this.$_.has(navLink, 'dropdownActive')){
                    this.navLinklist[index].dropdownActive = false;
                }
            });
        },
        navlinkObjectRender(routeObject, children = null){
            let obj = {name : routeObject.name};
            if(this.$_.has(routeObject, 'children') && children !== null){
                obj.dropdownActive = false;
                obj.children = children;
            }
            else{
                obj.to = routeObject.path;
            }
            if(this.$_.has(routeObject, 'description')){
                obj.description = routeObject.description;
            }
            if(this.$_.has(routeObject, 'icon')){
                obj.icon = routeObject.icon; 
            }
            return obj;
        }
    }
};
</script>

<style scoped >
div > i {
    text-align: center;
    vertical-align: middle;
}

.router-link-active,
.router-link-exact-active{
  @apply text-gray-900
}
</style>
