//css
import 'tailwindcss/tailwind.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

//js
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import Scrollto from 'vue-scrollto';

// import _ from lodash => if there is missing utility, but is more heavy then lodash/core
import _ from 'lodash/core';    

// var VueScrollTo = require('vue-scrollto');
 
// Vue.use(VueScrollTo);
// Vue.use(VueScrollTo, {
//     container: 'body',
//     duration: 500,
//     easing: 'ease',
//     offset: 0,
//     force: true,
//     cancelable: true,
//     onStart: false,
//     onDone: false,
//     onCancel: false,
//     x: false,
//     y: true
// });

const app = createApp(App);

Object.defineProperty(app.config.globalProperties, '$_', { value: _ });

app.use(router).use(Scrollto).mount('#app');