<template>
  <div class="relative">
    <button
      type="button"
      :class="[active ? 'text-gray-900' : 'text-gray-500', 'inline-flex items-center text-base font-medium bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500']"
      @click="setStatus()"
    >
      <span>{{ name }}</span>

      <svg
        class="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
    props:{
        name : {
            type : String,
            required: true
        },
        active:{
            type: Boolean,
            required : true
        }
    },
    methods:{
        setStatus(){
            this.$emit('clicked');
        }
    }
};
</script>