<template>
  <div
    :class="[
      active ? 'opacity-100 translate-y-0 z-30' : 'opacity-0 -translate-y-1 z-negative-1',
      'absolute inset-x-0 hidden transition duration-200 ease-in-out transform shadow-lg md:block',
    ]"
  >
    <div class="bg-white">
      <div
        class="grid px-4 py-6 mx-auto max-w-7xl gap-y-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16"
      >
        <router-link
          v-for="item in menuItems"
          :key="item.name"
          :to="item.to"
          class="flex flex-col justify-between p-3 -m-3 rounded-lg hover:bg-white"
        >
          <div class="flex md:h-full lg:flex-col">
            <div class="flex-shrink-0">
              <span
                class="inline-flex items-center justify-center w-10 h-10 text-white rounded-md bg-primary-500 sm:h-12 sm:w-12"
              >
                <!-- Heroicon name: shield-check -->
                <i :class="iconText(item.icon)" /> 
              </span>
            </div>
            <div
              class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4"
            >
              <div>
                <p class="text-base font-medium text-gray-900">
                  {{ item.name }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ item.description }}
                </p>
              </div>
              <p class="mt-2 text-sm font-medium text-primary-600 lg:mt-4">
                Learn more <span aria-hidden="true">&rarr;</span>
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        menuItems:{
            type : Object,
            required: true
        }
    },
    methods:{
        iconText(icon){
            return `${icon} fa-lg`;
        }
    }
};
</script>

<style>
.z-negative-1 {
  z-index: -1;
};
span > i {
    text-align: center;
    vertical-align: middle;
}
</style>