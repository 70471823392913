import { createWebHistory, createRouter } from 'vue-router';

const routes = [
    {
        navbar: true,
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    { path: '/:pathMatch(.*)*', component: () => import('@/views/404.vue')}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // (to, from, savedPosition)
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export { routes, router };