<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition
        name="slide"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <v-footer compagny-name="Simplicity made easy" />
  </div>
</template>
<script>
import Navbar from '@/components/layout/navbar';
import Simple from '@/components/layout/footer/simple';


export default {
    name: 'App',
    components: {
        'v-footer': Simple,
        'v-navbar' : Navbar
    },
};
</script>

<style>

.slide-leave-active,
.slide-enter-active{
  transition: all 0.25s;
}

.slide-leave-to,
.slide-enter-from {
  opacity: 0;
  transform: translateX(-5%);
}


.slide-enter-to{
  opacity: 1;
}

</style>
